<template>
  <div>
    <v-form ref="form" class="multi-col-validation mt-6">
      <v-card
        flat
      >
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">OFFICERS IN {{name_of_district}} </h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-container v-if="can_edit">
          <v-card-text>
            <v-col
              md="4"
              cols="12"
            ></v-col>
            <v-row>
              <v-col
                md="3"
                cols="12"
              >
                <v-select
                  v-model="positions"
                  dense
                  outlined
                  label="Position"
                  :items="positions_items"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col
                md="5"
                cols="12"
              >
                <v-combobox
                  :append-icon="icons.mdiAccountSearchOutline"
                  v-model="search"
                  @keyup="searching($event.target.value)"
                  :items="search_items"
                  item-value="id"
                  item-text="name"
                  placeholder="Search by (Account Name/Account #)"
                  ref="memberCombobox"
                  dense
                  outlined
                ></v-combobox>
              </v-col>
              <v-col
                md="2"
                cols="12"
              >
                <v-text-field
                  v-model="effective"
                  label="Effective Date"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="date"
                  @change="date_actions"
                ></v-text-field>
              </v-col>
              <v-col
                md="2"
                cols="12"
              >
                <v-text-field
                  v-model="expiration"
                  label="Expiration Date"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-col cols="12" v-show="alert">
            <v-alert
              color="warning"
              text
              class="mb-0"

            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn
              color="primary"
              class="me-3 mt-4"
              @click="save_branch_details"
              v-if="!saving"
            >
              Save changes
            </v-btn>
            <v-progress-circular
              :size=50
              :width="5"
              color="primary"
              indeterminate
              v-else
            ></v-progress-circular>
          </v-col>
        </v-container>
        <v-row>
          <v-col
            class="mx-2 mt-3"
            md="4"
            cols="12">
            <v-select
              v-model="year"
              dense
              outlined
              label="Year"
              :items="year_items"
              item-value="year"
              item-text="year"
              @change="load_officers"
            ></v-select>
          </v-col>
        </v-row>
        <v-toolbar dense dark color="info" class="mt-4">
          <v-toolbar-title><h4 class="font-weight-light">LIST OF OFFICERS</h4>
          </v-toolbar-title>
        </v-toolbar>
        <v-simple-table dense :key="key">
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">
                Position
              </th>
              <th class="text-uppercase">
                Name
              </th>
              <th class="text-center text-uppercase">
                Effective Date
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
              v-for="item in officers"
              :key="item.id"
            >
              <td>{{ item.position }}</td>
              <td>
                {{ item.name }}
              </td>
              <td class="text-center">
                {{ item.date_started }}
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!--        <v-data-table-->
        <!--          :headers="headers"-->
        <!--          :items="member_items"-->
        <!--          loading-text="Loading... Please wait"-->
        <!--        >-->
        <!--          <template v-slot:top>-->
        <!--            <v-toolbar dense dark color="warning" class="mt-4">-->
        <!--              <v-toolbar-title><h4 class="font-weight-light">LIST OF MEMBERS</h4>-->
        <!--              </v-toolbar-title>-->
        <!--            </v-toolbar>-->
        <!--          </template>-->
        <!--        </v-data-table>-->
        <!--        <v-data-table-->
        <!--          :height="beneficiary_items.length>0?height:0"-->
        <!--          :headers="headers"-->
        <!--          :items="beneficiary_items"-->
        <!--          loading-text="Loading... Please wait"-->
        <!--        >-->
        <!--          <template v-slot:top>-->
        <!--            <v-toolbar dense dark class="mt-4">-->
        <!--              <v-toolbar-title><h4 class="font-weight-light">LIST OF BENEFICIARY</h4>-->
        <!--              </v-toolbar-title>-->
        <!--            </v-toolbar>-->
        <!--          </template>-->
        <!--        </v-data-table>-->
        <!--        <v-data-table-->
        <!--          :height="scholar_items.length>0?height:0"-->
        <!--          :headers="headers"-->
        <!--          :items="scholar_items"-->
        <!--          loading-text="Loading... Please wait"-->
        <!--        >-->
        <!--          <template v-slot:top>-->
        <!--            <v-toolbar dense dark class="mt-4">-->
        <!--              <v-toolbar-title><h4 class="font-weight-light">LIST OF SCHOLAR</h4>-->
        <!--              </v-toolbar-title>-->
        <!--            </v-toolbar>-->
        <!--          </template>-->
        <!--        </v-data-table>-->
        <!--        <v-data-table-->
        <!--          :height="volunteer_items.length>0?height:0"-->
        <!--          :headers="headers"-->
        <!--          :items="volunteer_items"-->
        <!--          loading-text="Loading... Please wait"-->
        <!--        >-->
        <!--          <template v-slot:top>-->
        <!--            <v-toolbar dense dark class="mt-4">-->
        <!--              <v-toolbar-title><h4 class="font-weight-light">LIST OF VOLUNTEER</h4>-->
        <!--              </v-toolbar-title>-->
        <!--            </v-toolbar>-->
        <!--          </template>-->
        <!--        </v-data-table>-->
      </v-card>
    </v-form>
  </div>
</template>

<script>
  import {
    mdiAlertOutline,
    mdiCloudUploadOutline,
    mdiToggleSwitch,
    mdiToggleSwitchOff,
    mdiLeadPencil,
  } from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    props: {
      name_of_district: String,
      district_id: Number,
      is_sector: Number,
      can_edit: Boolean,
    },
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiToggleSwitchOff,
          mdiToggleSwitch,
          mdiLeadPencil,
        },
      }
    },
    data() {
      return {
        saving: false,
        alert: false,
        alert_message: '',

        positions: '',
        year: '',
        year_items: [],

        search: '',
        search_member: '',
        search_items: [],

        effective: '',
        expiration: '',
        officers: [],
        positions_items: ['DISTRICT PRESIDENT', 'VICE PRESIDENT','SECRETARY', 'TREASURER',  'PEACE OFFICER', 'PUBLIC IMAGE OFFICER','DISTRICT AFFAIR OFFICER', 'MEMBER'],
        member_items: [],
        beneficiary_items: [],
        scholar_items: [],
        volunteer_items: [],
        key: 0,
        height: 200,
        headers: [
          {text: 'Position', value: 'position'},
          {text: 'Name', value: 'name'},
          {text: 'Effective Date', value: 'date_started'},
        ],
      }
    },
    mounted() {
      this.get_available_year_officer_history()
        .then(response => {
          this.year_items = response.data
        })
      if (this.is_sector) {
        this.positions_items = ['CHAIRMAN', 'VICE CHAIRMAN FOR INTERNAL AFFAIRS', 'VICE CHAIRMAN FOR EXTERNAL AFFAIRS', 'SECRETARY', 'TREASURY', 'PIO', 'PUBLIC IMAGE', 'MEMBER']
      } else if (this.name_of_district.includes('GYMO')) {
        this.positions_items = ['NATIONAL CHAIRMAN', 'NATIONAL INTERNAL VICE CHAIRPERSON', 'NATIONAL EXTERNAL VICE CHAIRPERSON', 'NATIONAL SECRETARY', 'NATIONAL FINANCE OFFICERS', 'NATIONAL AUDITOR', 'BUSINESS OFFICERS', 'NATIONAL PIO', 'PEACE OFFICER', 'PRESIDENT', 'VICE PRESIDENT', 'TREASURY', 'SECRETARY', 'AUDITOR 1', 'AUDITOR 2', 'COUNCIL 1', 'COUNCIL 2', 'COUNCIL 3', 'COUNCIL 4', 'COUNCIL 5', 'ADVISER', 'MEMBER']
      }
    },
    computed: {
      ...mapGetters('authentication', ['position']),
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
      district_id: function () {
        this.load_officers()
      }
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('transaction_months', ['retrieve_all_transaction_year']),
      ...mapActions('members_information', ['search_all_regular_members']),
      ...mapActions('district_officers_history', ['save_new_officers_district', 'list_of_officers', 'get_available_year_officer_history']),
      load_officers() {
        const data = new FormData()
        data.append('district_id', this.district_id);
        data.append('year', this.year);
        this.list_of_officers(data)
          .then(response => {
            this.officers = response.data[0].officers
            this.member_items = response.data[0].members
            this.beneficiary_items = response.data[0].beneficiary
            this.scholar_items = response.data[0].scholar
            this.volunteer_items = response.data[0].volunteer
            this.key++
          })
      },
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_all_regular_members({
          search_word: value
        })
          .then(response => {
            this.search_items = response.data
          })
      },
      date_actions() {
        this.expiration = moment(this.effective, 'YYYY-MM-DD').add(1, 'year').format('MM/DD/YYYY')
      },
      reset() {
        this.saving = false
        this.alert = false
        this.alert_message = ''

      },
      save_branch_details() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('position', this.positions);
          data.append('date_started', this.effective);
          data.append('expiration_date', this.expiration);
          data.append('member_id', this.search.id);
          data.append('district_id', this.district_id);
          this.save_new_officers_district(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                this.alert = true
                this.alert_message = response.data
                this.saving = false
              } else {
                this.change_snackbar({
                  show: true,
                  color: color,
                  text: response.data,
                })
              }
              this.saving = false
              this.load_officers()
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
    }
  }
</script>
